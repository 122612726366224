import React, {useState} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import ExpandingCard from '../ExpandingCard';
import {
  S_1_BLOCK_1_HEADING,
  S_1_BLOCK_1_INTRO,
  S_1_BLOCK_2_HEADING,
  S_1_BLOCK_2_INTRO,
  S_1_BLOCK_2_CARDS,
  S_1_BLOCK_3_HEADING,
  S_1_BLOCK_3_USE_CASES_CONTENT,
  S_1_BLOCK_4_HEADING,
  S_1_BLOCK_4_SUBHEADING,
  S_1_BLOCK_5_HEADING,
  S_1_BLOCK_5_SUBHEADING,
  S_1_BLOCK_5_CONTENT,
  S_1_BLOCK_6_HEADING,
  S_1_BLOCK_6_INTRO,
  S_1_BLOCK_7_HEADING,
  S_1_BLOCK_7_INTRO,
  S_1_BLOCK_7_CARDS,
  S_1_BLOCK_TOOLS,
  S_1_BLOCK_TOOLS_HEADING,
  S_1_BLOCK_TOOLS_INTRO
} from '../../constants/services-pages';
import styles from './SoftwareDevService.module.scss';
import UseCases from '../UseCases';
import SuccessStoriesBanner from '../SuccessStoriesBanner';

const SoftwareDevService = () => {
  const [expanded, setExpanded] = useState(false);
  const { bg, minus, plus, icons, useCasesImages, successStoriesBackground, analyticsIcons, agileImg, servicesIcons } = useStaticQuery(graphql`
    query {
      agileImg: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          name: { eq: "agile" }
        }
      ) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth:862, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      servicesIcons: allFile(
        filter: {
          sourceInstanceName: { eq: "MTGC" }
          relativeDirectory: { eq: "services" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      successStoriesBackground: allFile(
        filter: {
          sourceInstanceName: { eq: "otherImages" }
          name: { eq: "success-stories-bg" }
          extension: { eq: "jpg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "services-development" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      plus: allFile(
          filter: {
              sourceInstanceName: { eq: "otherImages" }
              name: { eq: "plus" }
          }
      ) {
          nodes {
              name
              publicURL
          }
      }
      minus: allFile(
          filter: {
              sourceInstanceName: { eq: "otherImages" }
              name: { eq: "minus" }
          }
      ) {
          nodes {
              name
              publicURL
          }
      }
      icons: allFile(
          filter: {
              sourceInstanceName: { eq: "services-2024" }
              relativeDirectory: { eq: "1/icons-1" }
              extension: { eq: "svg" }
          }
      ) {
          nodes {
              name
              publicURL
          }
      }
      useCasesImages: allFile(
          filter: {
              sourceInstanceName: { eq: "services-2024" }
              relativeDirectory: { eq: "1/use-cases" }
              extension: { eq: "webp" }
          }
      ) {
          nodes {
              name
              publicURL
          }
      }
      analyticsIcons: allFile(
          filter: {
            sourceInstanceName: { eq: "services-2024" }
            relativeDirectory: { eq: "1/icons-2" }
          }
        ) {
          nodes {
            name
            publicURL
          }
        }
      }
  `);
  const iconsMap = icons.nodes.reduce((acc, icon) => ({...acc, [icon.name]: icon.publicURL}), {});
  const useCasesImagesMap = useCasesImages.nodes.reduce((acc, img) => ({...acc, [img.name]: img.publicURL}), {});
  const analyticsIconsMap = analyticsIcons.nodes.reduce((acc, icon) => ({...acc, [icon.name]: icon.publicURL,}),{});
  const servicesIconsMap = servicesIcons.nodes.reduce((acc, icon) => ({ ...acc, [icon.name]: icon.publicURL }),{});

  return (
    <div className={styles['sds']}>
      <InnerPagesUpperBanner
        bg={bg.nodes[0].publicURL}
        className={styles['sds-banner']}
        description={[S_1_BLOCK_1_INTRO]}
        descriptionClassName={styles['sds-banner-description']}
        text={[S_1_BLOCK_1_HEADING]}
        textClassName={styles['sds-banner-text']}
      />

      <div className={styles['section-container']}>
        <div className={styles['container']}>
          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {S_1_BLOCK_2_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{S_1_BLOCK_2_INTRO}</Typography>
          <div className={styles['card-list']}>
            {S_1_BLOCK_2_CARDS.map(({icon, text, title}) => {
              return <div className={styles['expanding-card']} key={icon}>
                <ExpandingCard
                  collapser={minus.nodes[0].publicURL}
                  expander={plus.nodes[0].publicURL}
                  img={iconsMap[icon]}
                  smallCard
                >
                  {{
                    collapsedView: <Typography
                      className={styles['expanding-card-heading']}
                      color={'secondary'}
                      variant={'h4'}
                    >
                      {title}
                    </Typography>,
                    expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                      {text.map((paragraph, index) => <p
                        className={styles['expanding-card-paragraph']}
                        key={`${icon}-${index}`}
                      >
                        {paragraph}
                      </p>)}
                    </div>
                  }}
                </ExpandingCard>
              </div>;
            })}
          </div>

        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['non-white']}`}>
        <div className={styles['container']}>
          <Typography align="center" className={styles['heading']} color="secondary" variant="h2">
            {S_1_BLOCK_3_HEADING}
          </Typography>
          <UseCases content={S_1_BLOCK_3_USE_CASES_CONTENT} images={useCasesImagesMap} />
        </div>
      </div>

      <SuccessStoriesBanner
      backgroundImage={successStoriesBackground.nodes[0].publicURL}
      className={styles['ready-banner']}
      descriptionClassName={styles['ready-banner-description']}
      heading={S_1_BLOCK_4_HEADING}
      subHeading={S_1_BLOCK_4_SUBHEADING}
      textClassName={styles['ready-banner-text']}
      />

      <div className={`${styles['custom-section']} ${styles['white-bg']}`}>
        <div className={styles['container']}>
          <Typography variant={'h2'} align={'center'} color={'secondary'}>
            {S_1_BLOCK_5_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>
            {S_1_BLOCK_5_SUBHEADING}
          </Typography>
          <div className={styles['card-list-2']}>
            {S_1_BLOCK_5_CONTENT.map((item, ind) => (
                <React.Fragment key={item.title}>
                  <div className={styles['card']}>
                    <div>
                      <img
                          src={analyticsIconsMap[item.icon]}
                          alt={item.icon}
                      />
                      <Typography className={styles['card-title']}>{item.title}</Typography>
                    </div>
                    <Typography>{item.text}</Typography>
                  </div>
                </React.Fragment>
            ))}
          </div>
        </div>
      </div>    



      <div className={`${styles['custom-section']} ${styles['white-bg']}`}>
        <div className={styles['container']}>

          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {S_1_BLOCK_TOOLS_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{S_1_BLOCK_TOOLS_INTRO}</Typography>

          <div className={styles['our-service']}>
            {S_1_BLOCK_TOOLS.content.map((block, index) => (
              <div key={index} className={styles['service-row']}>
                <Typography variant="h4" className={styles['category-title']}>
                  {block.title}
                </Typography>
                <div className={styles['tags-list']}>
                  {block.body.map((item, ind) => (
                    <span key={ind} className={styles['tag']}>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className={styles['our-service-mobile']}>
            {S_1_BLOCK_TOOLS.content.map((item, i) => (
              <div className={styles['card-container']} key={i}>
                <ExpandingCard smallCard>
                  {{collapsedView: (
                      <>
                        <Typography
                          variant={'h4'}
                          style={{
                            fontSize: '14px',
                            lineHeight: '24px',
                            width: '100%'
                          }}
                        >
                          {item.title}
                        </Typography>
                      </>
                    ),
                    expandedView: (
                      <div className={'expanding-card-container'}>
                        <hr
                          style={{
                            background: '#DFE4ED'
                          }}
                        />
                        <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                          <ul>
                            {item.body.filter((item, ind) => ind % 2 === 0).map(item =>  (
                              <li key={item}>
                                <div className={styles['bullet-container']}>
                                  <div className={styles['bullet']}/>
                                </div>
                                <Typography>{item}</Typography>
                              </li>
                            ))}
                          </ul>
                          <ul>
                            {item.body.filter((item, ind) => ind % 2 !== 0).map(item =>  (
                              <li key={item}>
                                <div className={styles['bullet-container']}>
                                  <div className={styles['bullet']}/>
                                </div>
                                <Typography>{item}</Typography>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}}
                </ExpandingCard>
              </div>
            ))}
          </div>

        </div>
      </div>




      <div className={`${styles['section-container']} ${styles['agile']}`}>
        <div className={styles['container']}>
          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {S_1_BLOCK_6_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{S_1_BLOCK_6_INTRO}</Typography>
          <div className={styles['img-wrapper']}>
            <img src={agileImg.nodes[0].publicURL} alt="agile illustration" />
          </div>
        </div>
      </div>

      <div className={`${styles['section-container']} ${styles['grey-bg']}`}>
        <div className={styles['container']}>
          <Typography className={styles['heading']} color={'secondary'} variant={'h2'} align={'center'}>
            {S_1_BLOCK_7_HEADING}
          </Typography>
          <Typography className={styles['subheading']}>{S_1_BLOCK_7_INTRO}</Typography>
          <div className={styles['card-list']}>
            {S_1_BLOCK_7_CARDS.map(({icon, text, title}) => {
              return <div className={styles['expanding-card']} key={icon}>
                <ExpandingCard
                  collapser={minus.nodes[0].publicURL}
                  expander={plus.nodes[0].publicURL}
                  smallCard
                >
                  {{
                    collapsedView: <Typography
                      className={styles['expanding-card-heading']}
                      color={'secondary'}
                      variant={'h4'}
                    >
                      {title}
                    </Typography>,
                    expandedView: <div className={`expanding-card-container ${styles['expanding-card-container']}`}>
                      {text.map((paragraph, index) => <p
                        className={styles['expanding-card-paragraph']}
                        key={`${icon}-${index}`}
                      >
                        {paragraph}
                      </p>)}
                    </div>
                  }}
                </ExpandingCard>
              </div>;
            })}
          </div>
        </div>
      </div>

    </div>
  );
};

export default SoftwareDevService;
